<template>
	<div class="contribute">
		<w-navTab titleText="缴纳会费"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<!-- 未缴纳会费 -->
		<unpaidBute v-if="bute == 1"></unpaidBute>
		<!-- 已缴纳会费 -->
		<paidBute v-else :detail="detail"></paidBute>
	</div>
</template>

<script>
import Vant from '@/vendor/vant.js';
import unpaidBute from '@/views/user/contribute/components/unpaidBute.vue';
import paidBute from '@/views/user/contribute/components/paidBute.vue';
import LoadingPage from '@/components/LoadingPage';

import UserApi from '@/api/user';
export default {
	inject: ['className', 'districtType', 'themeColor'],
	name: 'Contribute',
	data() {
		return {
			bute: 1,
			detail:"",
			loadingPage: true,
		};
	},
	created() {
		let id = this.$store.state.user.userInfo.district_id
		UserApi.userIsPayment({Districtid: id})
			.then(result=>{
				this.bute = result.data.is_payment
				this.detail = result.data
				this.loadingPage = false;
			},error=>{
				Vant.Toast(error.msg);
			})
		
	},
	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		}
	},
	components:{
		unpaidBute,
		paidBute,
		LoadingPage,
	}
};
</script>

<style lang="less" scoped>
.contribute {
	
}
</style>
