<template>
	<div :class="['unpaidBute', className('unpaidBute-')]">
		<div class="contribute-head">
			<div class="head-top">
				<div class="head-top-title">
					缴纳会费
				</div>
				<div class="head-top-text" v-if="userInfo.role_type == 3">立即缴费，享受平安义警权益</div>
				<div class="head-top-text" v-else>立即缴费，享受平安志愿者权益</div>
			</div>
			<div class="head-bottom">
				<div class="head-bottom-left">￥100/3年</div>
				<div class="head-bottom-right" @click="$router.push({ name: 'ContributePay' })">会费缴纳</div>
			</div>
		</div>
		<div class="contribute-middle" v-if="navShow == 1">
			<div class="middle-nav">
				<div :class="['middle-nav-text',{'middle-nav-text-active': navShow == 2}]" @click="navShow = 1">平安义警权益</div>
				<div :class="['middle-nav-text',{'middle-nav-text-active': navShow == 1}]" @click="navShow = 2">志愿者权益</div>
			</div>
			<div class="content">
				<div class="content-item"><img src="@/assets/img/dues1.png" alt="" /></div>
				<div class="content-item"><img src="@/assets/img/dues2.png" alt="" /></div>
				<div class="content-item"><img src="@/assets/img/dues3.png" alt="" /></div>
			</div>
			<div class="content-footer">
				<div class="content-footer-text">电子证件</div>
				<div class="content-footer-text" style="margin-right: 10px;">保险</div>
				<div class="content-footer-text" style="margin-right: 8px;">作训服</div>
			</div>
		</div>
		<div class="contribute-middle-right" v-else>
			<div class="middle-nav">
				<div :class="['middle-nav-text',{'middle-nav-text-active': navShow == 2}]" @click="navShow = 1">平安义警权益</div>
				<div :class="['middle-nav-text',{'middle-nav-text-active': navShow == 1}]" @click="navShow = 2">志愿者权益</div>
			</div>
			<div class="content-box">
				<div class="content-item"><img src="@/assets/img/dues2.png" alt="" /></div>
				<div class="content-item"><img src="@/assets/img/dues3.png" alt="" /></div>
			</div>
			<div class="content-footer-box">
				<div class="content-footer-text">工作保险</div>
				<div class="content-footer-text">工作马甲</div>
			</div>
		</div>
		<div class="contribute-footer">
			<div class="footer-head">
				<div class="footer-img" v-if="districtType() == 1"><img src="@/assets/img/dues4.png" alt="" /></div>
				<div class="footer-img" v-if="districtType() == 2"><img src="@/assets/img/dues44.png" alt="" /></div>
				<div class="footer-title">会费缴纳须知</div>
				<div class="footer-img" v-if="districtType() == 1"><img src="@/assets/img/dues4.png" alt="" /></div>
				<div class="footer-img" v-if="districtType() == 2"><img src="@/assets/img/dues44.png" alt="" /></div>
			</div>
			<div class="footer-text">1、协会会费是以平安志愿者或义警队员自愿为前提进行缴纳，并非强制性执行。</div>
			<div class="footer-text">2、不已营利为目的，所有会费收入用于协会公益所需。</div>
			<div class="footer-text">
				3、一次性缴纳100元会费后将会立即成为正式会员，享受会员权益，有效期三年。平安志愿者会员权益: 配送工作马甲，免费办理一年的团险;平安义警会员权益: 电子证件、义警服装、免费办理一年的团险。
			</div>
			<div class="footer-text">4、会费缴纳成功，需要根据页面提示前往辖区协会领取马甲，办理保险。</div>
			<div class="footer-text">5、平安志愿者升级成平安义警后，会员权益也会自动进行更换，爱心值连续计算，届时您需要重新领取义警服装，办理保险。</div>
			<div class="footer-text">6、会费到期，需继续缴会费，如要退出协会需交还平安义警警衔标识。</div>
		</div>
		<div class="footer-btn"><div class="btn-text" @click="$router.push({ name: 'ContributePay' })">会费缴纳</div></div>
	</div>
</template>

<script>
export default {
	inject: ['className', 'districtType', 'themeColor'],
	data() {
		return {
			navShow: 1,
		};
	},
	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		}
	},
};
</script>

<style lang="less" scoped>
.unpaidBute-city{
	.contribute-head{
		background: url(../../../../assets/img/duesTop1.png);
		.head-bottom{
			.head-bottom-right{
				background: linear-gradient(101.48deg, #71B5F4 0%, #226DDE 100%);
			}
		}
	}
	.contribute-middle{
		background: url(../../../../assets/img/duesbody1.png);
	}
	.contribute-middle-right{
		background: url(../../../../assets/img/duesbody2.png);
	}
	.contribute-footer{
		.footer-title{
			color: #3377FF;
		}
	}
	.footer-btn{
		.btn-text{
			background: #3377FF;
		}
	}
}
	
.unpaidBute-district{
	.contribute-head{
		background: url(../../../../assets/img/duesTop11.png);
		.head-bottom{
			.head-bottom-right{
				background-color: #ff6969;
			}
		}
	}
	.contribute-middle{
		background: url(../../../../assets/img/duesbody11.png);
	}
	.contribute-middle-right{
		background: url(../../../../assets/img/duesbody22.png);
	}
	.contribute-footer{
		.footer-title{
			color: #ff6969;
		}
	}
	.footer-btn{
		.btn-text{
			background: #ff6969;
		}
	}
}

.unpaidBute {
	padding: 10px;
	padding-bottom: 80px;

	.contribute-head{
		width: 100%;
		height: 180px;
		background-size: cover;
		box-shadow: inset -1px -1px 1px rgba(255, 255, 255, 0.4);
		border-radius: 12px;
		margin-bottom: 10px;
		padding: 20px 20px 16px 20px;
		box-sizing: border-box;

		.head-top{
			.head-top-title{
				font-weight: bold;
				font-size: 30px;
				line-height: 32px;
				color: #FFF5DB;
				text-shadow: 0px 0px 2px rgba(78, 85, 255, 0.51)
			}
			.head-top-text{
				font-weight: bold;
				font-size: 14px;
				line-height: 30px;
				color: #FFF5DB;
				opacity: 0.9;
				text-shadow: 0px 0px 2px rgba(78, 85, 255, 0.51);
			}
		}
		.head-bottom{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 48px;

			.head-bottom-left{
				font-weight: bold;
				font-size: 15px;
				line-height: 30px;
				color: #FFB23F;
			}
			.head-bottom-right{
				font-weight: bold;
				font-size: 14px;
				line-height: 30px;
				color: #FFF;
				box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.4);
				border-radius: 100px;
				padding: 3px 20px;
				box-sizing: border-box;
			}
		}
	}
	.contribute-middle{
		width: 100%;
		height: 188px;
		background-size: cover;
		border-radius: 12px;
		margin-bottom: 10px;
		padding: 5px 32px 10px 32px;
		box-sizing: border-box;
	}
	.middle-nav{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 3px;
		margin-bottom: 33px;
	
		.middle-nav-text{
			font-weight: bold;
			font-size: 16px;
			line-height: 30px;
			color: #FFF;
		}
		.middle-nav-text-active{
			font-weight: bold;
			font-size: 16px;
			line-height: 30px;
			color: #777;
		}
	}
	.content{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
	
		.content-item{
			width: 70px;
			height: 70px;
			img{
				width: 100%;
				height: 100%;
				border-radius: 100px;
			}
		}
	}
	.content-box{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	
		.content-item{
			width: 70px;
			height: 70px;
			margin: 0 25px;
			img{
				width: 100%;
				height: 100%;
				border-radius: 100px;
			}
		}
	}
	.content-footer{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 3px;
		.content-footer-text{
			font-weight: bold;
			font-size: 16px;
			line-height: 30px;
			color: #FFF;
		}
	}
	.content-footer-box{
		display: flex;
		justify-content: center;
		align-items: center;
	
		.content-footer-text{
			margin: 0 27px;
			font-weight: bold;
			font-size: 16px;
			line-height: 30px;
			color: #FFF;
		}
	}
	.contribute-middle-right{
		width: 100%;
		height: 188px;
		background-size: cover;
		border-radius: 12px;
		margin-bottom: 10px;
		padding: 5px 32px 10px 32px;
		box-sizing: border-box;
	}
	.contribute-footer{
		width: 100%;
		background: #FFF;
		border-radius: 12px;
		padding: 15px 20px;
		box-sizing: border-box;

		.footer-head{
			display: flex;
			justify-content: center;
			align-items: center;

			.footer-img{
				width: 13px;
				height: 14px;
				img{
					width: 100%;
					height: 100%;
				}
			}
			.footer-title{
				font-weight: bold;
				font-size: 18px;
				line-height: 30px;
				text-align: center;
				margin: 0 10px;
			}
		}
		.footer-text{
			font-size: 16px;
			line-height: 22px;
			color: #222;
			margin-top: 10px;
		}
	}
	.footer-btn{
		position: fixed;
		bottom: 20px;
		left: 0;
		width: 100%;
		padding: 0 10px;
		box-sizing: border-box;

		.btn-text{
			font-weight: bold;
			font-size: 18px;
			line-height: 30px;
			color: #FFF;
			border-radius: 100px;
			text-align: center;
			padding: 10px 0;
			box-sizing: border-box;
		}
	}
}
</style>
