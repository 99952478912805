<template>
	<div :class="['paidBute', className('paidBute-')]">
		<div class="contribute-head">
			<div class="head-top">
				<div class="head-top-title">
					<div class="head-top-title-left">
						您已是正式会员
					</div>
					<div class="head-top-title-right" @click="$router.push({ name: 'ContributeRecord' })">
						缴纳记录
					</div>
				</div>
				<div class="head-top-text" v-if="userInfo.role_type == 3">
					<div class="text">
						您可以享受平安义警权益
					</div>
					<div class="text" @click="$router.push({ name: 'LevelDescription' })">
						查看证件
					</div>
				</div>
				<div class="head-top-text" v-else>您可以享受平安志愿者权益</div>
			</div>
			<div class="head-bottom">
				<div class="head-bottom-left">会费到期时间：{{detail.overdue_time}}</div>
				<div class="head-bottom-right" @click="$router.push({ name: 'ContributePay' })">到期续费</div>
			</div>
		</div>
		<div class="contribute-middle" >
			<div class="middle-item">
				<div class="item-title">
					电子证件
				</div>
<!-- 				<div class="item-text" v-if="detail.is_certificate == 1">
					现场领取
				</div> -->
				<div class="item-text-active">
					<van-icon v-if="districtType() == 1" name="passed" color="#3377FF" size="18px" style="margin-right: 4px;" />
					<van-icon v-if="districtType() == 2" name="passed" color="#ff6969" size="18px" style="margin-right: 4px;" />已领取
				</div>
			</div>
			<div class="middle-item" v-if="userInfo.role_type == 3">
				<div class="item-title">
					义警马甲
				</div>
				<div class="item-text" v-if="detail.is_uniform == 1">
					现场领取
				</div>
				<div class="item-text-active" v-else>
					<van-icon v-if="districtType() == 1" name="passed" color="#3377FF" size="18px" style="margin-right: 4px;" />
					<van-icon v-if="districtType() == 2" name="passed" color="#ff6969" size="18px" style="margin-right: 4px;" />已领取
				</div>
			</div>
			<div class="middle-item" v-if="userInfo.role_type == 2">
				<div class="item-title">
					志愿者马甲
				</div>
				<div class="item-text" v-if="detail.is_waistcoat == 1">
					现场领取
				</div>
				<div class="item-text-active" v-else>
					<van-icon v-if="districtType() == 1" name="passed" color="#3377FF" size="18px" style="margin-right: 4px;" />
					<van-icon v-if="districtType() == 2" name="passed" color="#ff6969" size="18px" style="margin-right: 4px;" />已领取
				</div>
			</div>
			<div class="middle-item">
				<div class="item-title">
					办理保险
				</div>
				<div class="item-text" v-if="detail.is_insurance == 1">
					正在办理
				</div>
				<div class="item-text-active" v-else>
					<van-icon v-if="districtType() == 1" name="passed" color="#3377FF" size="18px" style="margin-right: 4px;" />
					<van-icon v-if="districtType() == 2" name="passed" color="#ff6969" size="18px" style="margin-right: 4px;" />已办理
				</div>
				<div class="middle-item-bottom" v-if="detail.is_insurance != 1">
					保险时间：{{detail.insurance_start_time}} - {{detail.insurance_end_time}}
				</div>
			</div>
			
		</div>
		<div class="contribute-footer">
			<div class="footer-head">
				<div class="footer-img" v-if="districtType() == 1"><img src="@/assets/img/dues4.png" alt="" /></div>
				<div class="footer-img" v-if="districtType() == 2"><img src="@/assets/img/dues44.png" alt="" /></div>
				<div class="footer-title">权益领取须知</div>
				<div class="footer-img" v-if="districtType() == 1"><img src="@/assets/img/dues4.png" alt="" /></div>
				<div class="footer-img" v-if="districtType() == 2"><img src="@/assets/img/dues44.png" alt="" /></div>
			</div>
			<div class="footer-text">1、平安志愿者，平安义警请携带好本人有效证件，前往自己所在辖区协会现场领取服装。</div>
			<div class="footer-text">2、录取前请联系自己所属大队工作人员，确保服装已准备好。</div>
		</div>
		<div class="footer-btn" v-if="detail.overdue_tip == 1"><div class="btn-text" @click="$router.push({ name: 'ContributePay' })">到期续费</div></div>
		<div class="footer-btn" v-if="detail.overdue_tip == 0"><div class="btn-text active">已缴会费</div></div>
	</div>
</template>

<script>
export default {
	inject: ['themeColor', 'className', 'districtType'],
	props: {
		detail: {
			detail: Object,
			default: '',
		},
	},
	data() {
		return {
			
		};
	},
	created() {
		let id = this.$store.state.user.userInfo.role_type
		console.log(id);
	},
	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		}
	},
};
</script>

<style lang="less" scoped>
.paidBute-city{
	.contribute-head{
		background: url(../../../../assets/img/duesTop1.png);
		.head-bottom{
			.head-bottom-right{
				background: linear-gradient(101.48deg, #71B5F4 0%, #226DDE 100%);
			}
		}
	}
	.contribute-middle{
		background: url(../../../../assets/img/duesbody1.png);
		.middle-item{
			.item-text-active{
				color: #3377FF;
			}
		}
	}
	.contribute-middle-right{
		background: url(../../../../assets/img/duesbody2.png);
	}
	.contribute-footer{
		.footer-title{
			color: #3377FF;
		}
	}
	.footer-btn{
		.btn-text{
			background: #3377FF;
		}
		.active{
			background: #CDCBCC;
		}
	}
}
	
.paidBute-district{
	.contribute-head{
		background: url(../../../../assets/img/duesTop11.png);
		.head-bottom{
			.head-bottom-right{
				background-color: #ff6969;
			}
		}
	}
	.contribute-middle{
		background: url(../../../../assets/img/duesbody11.png);
		.middle-item{
			.item-text-active{
				color: #ff6969;
			}
		}
	}
	.contribute-middle-right{
		background: url(../../../../assets/img/duesbody22.png);
	}
	.contribute-footer{
		.footer-title{
			color: #ff6969;
		}
	}
	.footer-btn{
		.btn-text{
			background: #ff6969;
		}
		.active{
			background: #CDCBCC;
		}
	}
}


.paidBute {
	padding: 10px;
	padding-bottom: 80px;

	.contribute-head{
		width: 100%;
		height: 180px;
		background-size: cover;
		box-shadow: inset -1px -1px 1px rgba(255, 255, 255, 0.4);
		border-radius: 12px;
		margin-bottom: 10px;
		padding: 20px 20px 16px 20px;
		box-sizing: border-box;

		.head-top{
			.head-top-title{
				display: flex;
				justify-content: space-between;
				
				.head-top-title-left{
					font-size: 30px;
					line-height: 32px;
					color: #FFF5DB;
					text-shadow: 0px 0px 2px rgba(78, 85, 255, 0.51)
				}
				.head-top-title-right{
					font-size: 16px;
					line-height: 30px;
					color: #FFF5DB;
				}
			}
			.head-top-text{
				font-weight: bold;
				font-size: 14px;
				line-height: 30px;
				color: #FFF5DB;
				opacity: 0.9;
				text-shadow: 0px 0px 2px rgba(78, 85, 255, 0.51);
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
		.head-bottom{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 48px;

			.head-bottom-left{
				font-weight: bold;
				font-size: 15px;
				line-height: 30px;
				color: #FFB23F;
			}
			.head-bottom-right{
				font-weight: bold;
				font-size: 14px;
				line-height: 30px;
				color: #FFF;
				box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.4);
				border-radius: 100px;
				padding: 3px 20px;
				box-sizing: border-box;
			}
		}
	}
	.contribute-middle{
		width: 100%;
		background: #FFF;
		border-radius: 12px;
		padding: 5px 20px;
		box-sizing: border-box;
		margin-bottom: 10px;
		 
		 .middle-item:last-child{
			 border-bottom: 0;
			 padding-bottom: 30px;
		 }
		.middle-item{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			border-bottom: 1px solid #eee;
			position: relative;
		
			.item-title{
				font-weight: bold;
				font-size: 16px;
				line-height: 30px;
				color: #222;
			}
			.item-text{
				font-weight: bold;
				font-size: 16px;
				line-height: 30px;
				color: #777;
			}
			.item-text-active{
				font-weight: bold;
				font-size: 16px;
				line-height: 30px;
			}
			.middle-item-bottom{
				font-size: 14px;
				line-height: 30px;
				color: #777;
				position: absolute;
				bottom: 0;
			}
		}

	}
	

	.contribute-footer{
		width: 100%;
		background: #FFF;
		border-radius: 12px;
		padding: 15px 20px;
		box-sizing: border-box;

		.footer-head{
			display: flex;
			justify-content: center;
			align-items: center;

			.footer-img{
				width: 13px;
				height: 14px;
				img{
					width: 100%;
					height: 100%;
				}
			}
			.footer-title{
				font-weight: bold;
				font-size: 18px;
				line-height: 30px;
				text-align: center;
				margin: 0 10px;
			}
		}
		.footer-text{
			font-size: 16px;
			line-height: 22px;
			color: #222;
			margin-top: 10px;
		}
	}
	.footer-btn{
		position: fixed;
		bottom: 20px;
		left: 0;
		width: 100%;
		padding: 0 10px;
		box-sizing: border-box;

		.btn-text{
			font-weight: bold;
			font-size: 18px;
			line-height: 30px;
			color: #FFF;
			border-radius: 100px;
			text-align: center;
			padding: 10px 0;
			box-sizing: border-box;
		}
	}
}
</style>
